<template>
  <div v-if="content">
    <template
      v-for="(item, index) in content"
      :key="index"
    >
      <div
        v-if="item._type !== 'table'"
        class="prose prose-invert w-full max-w-none break-words mt-3"
        @click.stop="handleActionLinks"
        v-html="$sanityBlocksToHtml(item)"
      />
      <CmsTable v-if="item._type === 'table'" :rows="item.rows"/>
    </template>
  </div>
</template>

<script setup>
const props = defineProps({
  query: {
    type: Object
  },
  queryVariables: {
    type: Object
  },
  preLoadedContent: {
    type: Object,
    default: () => ({}),
  },
});

const content = ref(null);

const emits = defineEmits(['data-loaded', 'modal-open',]);
const { $rollbar, $sanityBlocksToHtml, $useVillusQuery } = useNuxtApp();

watch(
  () => props.query,
  (newVal) => {
    if (newVal) { fetchData(); }
  }
);

watch(
  () => props.preLoadedContent,
  (newVal) => {
    if (newVal) { fetchData(); }
  }
);

async function fetchData() {
  if (Object.keys(props.preLoadedContent).length > 0) {
    content.value = props.preLoadedContent.contentRaw;
    emits('data-loaded', content.value);
    return;
  }

  const getResults = $useVillusQuery(props.query, props.queryVariables);
  const { data, error } = await getResults();

  if (error) {
    $rollbar.error('fetchData::', error);
    emits('data-loaded', null);
    return;
  }
  const selectionName = props.query.definitions[0].selectionSet.selections[0].name.value;
  const res = data[selectionName][0];

  emits('data-loaded', res);

  if (res?.contentRaw) {
    content.value = res.contentRaw;
  }
}

function handleActionLinks(event) {
  const target = event.target.closest('a');
  if (!target) { return; }

  const href = target.getAttribute('href');
  if (href?.startsWith('modal')) {
    event.preventDefault();
    emits('modal-open', href.split(':')[1]);
  }
}

await fetchData();
</script>
